import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout } from '@radix-ui/themes';

import { PageLoadingIndicator } from 'app/components';
import {
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormPanel,
  FormRadioGroup
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { LICENCE_OPTIONS } from 'app/constants/customers';
import { URLS } from 'app/constants/routes';
import {
  useFetchCustomer,
  useUpdateCustomerMutation
} from 'app/queries/useCustomerManagementQueries';
import { UpdateCustomerRequest } from 'app/types/customers';
import { getMessageFromError } from 'app/utils/error-utils';

export const UpdateCustomer: FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [showNameError, setShowNameError] = useState(false);
  const [showCustomerLicenceError, setShowCustomerLicenceError] = useState(false);
  const [customer, setCustomer] = useState<UpdateCustomerRequest | undefined>();

  const { data: originalCustomer, isFetching: isOriginalCustomerLoading } = useFetchCustomer(
    !!params.id,
    params.id || ''
  );

  const { mutate, isPending, isError, error } = useUpdateCustomerMutation();

  const handleCustomerLicenceChange = (val: string) => {
    setShowCustomerLicenceError(!val);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const name = formData.get('name');
    const m365Licence = formData.get('m365Licence');

    setShowNameError(!name);
    setShowCustomerLicenceError(!m365Licence);

    if (!name || !m365Licence) return;

    mutate(
      {
        id: originalCustomer!.id,
        customer: { name, m365Licence, enabled: originalCustomer?.enabled } as UpdateCustomerRequest
      },
      {
        onSuccess: () => {
          setCustomer(undefined);
          navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
  };

  useEffect(() => {
    if (!isOriginalCustomerLoading && originalCustomer) {
      setCustomer({
        name: originalCustomer.name,
        m365Licence: originalCustomer.m365Licence,
        enabled: originalCustomer.enabled
      });
    }
  }, [isOriginalCustomerLoading, originalCustomer]);

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.CUSTOMERS}>
      <FormPanel
        heading='Edit Customer'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
        isDisabled={!originalCustomer}
      >
        {isOriginalCustomerLoading && <PageLoadingIndicator />}
        {!isOriginalCustomerLoading && isError && (
          <Callout.Root color='red' size='1' data-testid='customer-form-error'>
            <Callout.Icon>
              <Warning role='none' />
            </Callout.Icon>
            <Callout.Text>Unable to update customer.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}

        {originalCustomer && customer && (
          <>
            <FormField name='name' data-testid='customer-name-field'>
              <FormLabel>Name</FormLabel>
              <FormInput
                name='name'
                required
                disabled={isPending || !originalCustomer}
                defaultValue={customer.name}
              />
              <FormMessage match='valueMissing' forceMatch={showNameError}>
                This field is required
              </FormMessage>
            </FormField>

            <FormPrimitive.Field name='tenantId' data-testid='customer-tenantId-field'>
              <FormLabel>Tenant ID</FormLabel>
              <FormInput name='tenantId' readOnly defaultValue={originalCustomer.tenantId} />
            </FormPrimitive.Field>

            <FormPrimitive.Field name='m365Licence' data-testid='customer-licence-field'>
              <FormLabel id='customer-licence-label' defaultLabel>
                M365 licence
              </FormLabel>
              <FormRadioGroup
                id='customer-licence-field'
                aria-labelledby='customer-licence-label'
                name='m365Licence'
                required
                disabled={isPending || !originalCustomer}
                defaultValue={customer.m365Licence}
                onInvalid={() => setShowCustomerLicenceError(true)}
                items={LICENCE_OPTIONS}
                onValueChange={handleCustomerLicenceChange}
              />
              <FormMessage match='valueMissing' forceMatch={showCustomerLicenceError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>
          </>
        )}
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};

import { PauseCircle, PlayCircle } from '@phosphor-icons/react';
import { IconButton, Spinner } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { useUpdateCustomerMutation } from 'app/queries/useCustomerManagementQueries';
import { Customer } from 'app/types';

const useStyles = tss.withName('CustomerStatusChange').create(() => ({
  button: {
    margin: 0,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'var(--slate-200)'
    }
  }
}));

type CustomerStatusChangeProps = {
  customer: Customer;
  className: string;
  onSuccess: () => void;
};

export const CustomerStatusChange: React.FC<CustomerStatusChangeProps> = ({
  customer,
  className,
  onSuccess
}) => {
  const { classes } = useStyles();

  const { mutate, isPending } = useUpdateCustomerMutation();

  const handleMutation = async () => {
    mutate(
      {
        id: customer.id,
        customer: {
          enabled: !customer.enabled,
          name: customer.name,
          m365Licence: customer.m365Licence
        }
      },
      {
        onSuccess: () => onSuccess()
      }
    );
  };

  return isPending ? (
    <Spinner />
  ) : (
    <IconButton
      onClick={handleMutation}
      disabled={isPending}
      className={clsx(classes.button, className)}
      variant='ghost'
      title={customer.enabled ? 'Disable customer' : 'Enable customer'}
      aria-label={customer.enabled ? 'Disable customer' : 'Enable customer'}
    >
      {customer.enabled ? (
        <PauseCircle size={20} role='none' />
      ) : (
        <PlayCircle size={20} role='none' />
      )}
    </IconButton>
  );
};

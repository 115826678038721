import { FunctionComponent } from 'react';
import { Box, CheckboxGroup, Flex } from '@radix-ui/themes';

import {
  DASHBOARD_STATUS,
  DASHBOARD_STATUS_KEYS,
  DASHBOARD_STATUS_LABELS
} from 'app/constants/dashboard';
import { useCommonFilterStyles } from 'app/constants/filters';
import { CommonFilterProps } from 'app/types/filters';

import { DashboardStatusIcon } from '../Dashboard';

import { CheckboxFilter } from './common/CheckboxFilter';

const STATUS_KEYS = Object.keys(DASHBOARD_STATUS) as DASHBOARD_STATUS_KEYS[];

export const DashboardStatusFilter: FunctionComponent<CommonFilterProps> = props => {
  const { classes } = useCommonFilterStyles();

  return (
    <CheckboxFilter
      label='Status'
      labelId='status-filter'
      {...props}
      options={STATUS_KEYS.map(val => (
        <CheckboxGroup.Item
          key={val}
          value={val}
          className={classes.checkboxItem}
          data-testid={`status-filter-item-${val}`}
        >
          <Flex gap='2' align='center'>
            <Box as='span' height='24px'>
              <DashboardStatusIcon size={24} status={DASHBOARD_STATUS[val]} />
            </Box>

            {DASHBOARD_STATUS_LABELS[val]}
          </Flex>
        </CheckboxGroup.Item>
      ))}
    />
  );
};

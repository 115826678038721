import { FunctionComponent, ReactNode } from 'react';
import { FlagBannerFold, Siren } from '@phosphor-icons/react';
import { Box, Card, Flex, Text } from '@radix-ui/themes';
import { clsx } from 'clsx';
import { tss } from 'tss-react';

const useStyles = tss
  .withName('ActionCard')
  .withNestedSelectors<'cardName'>()
  .create(({ classes }) => ({
    card: {
      display: 'flex',
      gap: '16px',
      padding: '0',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
      background: 'var(--white)',

      '&:after': {
        boxShadow: 'none'
      }
    },
    cardName: {
      width: '120px',
      padding: '16px',
      gap: '8px',
      color: 'var(--font-negative-color)',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    cardIncomplete: {
      color: 'var(--font-primary-color)'
    },
    actions: {
      padding: '8px 0'
    },
    quickWins: {
      border: '1px solid var(--rag-purple)',
      [`.${classes.cardName}`]: {
        background: 'var(--rag-purple)'
      }
    },
    highRisk: {
      border: '1px solid var(--red-a5)',
      [`.${classes.cardName}`]: {
        background: 'var(--red-a5)'
      }
    },
    incomplete: {
      border: '1px solid var(--slate-200)',
      [`.${classes.cardName}`]: {
        background: 'var(--slate-200)'
      }
    },
    title: {
      fontSize: '16px',
      fontWeight: 900,
      lineHeight: '12px',
      letterSpacing: '-0.12px'
    },
    count: {
      fontSize: '41px',
      lineHeight: '29px',
      letterSpacing: '-4.1px'
    }
  }));

type SolutionsProps = {
  children: ReactNode;
  variant: 'quickWins' | 'highRisk' | 'incomplete';
  total: number;
};

const getVariantTitle = (variant: SolutionsProps['variant']) => {
  switch (variant) {
    case 'quickWins':
      return 'Quick wins';
    case 'highRisk':
      return 'High risk';
    case 'incomplete':
      return 'Incomplete';
  }
};

const getVariantIcon = (variant: SolutionsProps['variant']) => {
  switch (variant) {
    case 'quickWins':
      return <FlagBannerFold size={24} weight='fill' role='none' />;
    case 'highRisk':
      return <Siren size={24} weight='fill' role='none' />;
    case 'incomplete':
      return;
  }
};

const getVariantDescription = (variant: SolutionsProps['variant'], total: number) => {
  switch (variant) {
    case 'quickWins':
      return `${total} Quick Win controls: Incomplete controls with a high security risk and low business impact.`;
    case 'highRisk':
      return `${total} High Risk controls: Incomplete controls with a high security risk.`;
    case 'incomplete':
      return `${total} Incomplete controls.`;
  }
};

export const ActionCard: FunctionComponent<SolutionsProps> = ({ children, variant, total }) => {
  const { classes } = useStyles();

  return (
    <Card className={clsx(classes.card, classes[variant])} variant='classic'>
      <Box
        className={clsx(classes.cardName, variant === 'incomplete' && classes.cardIncomplete)}
        tabIndex={0}
        aria-label={getVariantDescription(variant, total)}
      >
        <Flex
          gap='2'
          direction='column'
          align='stretch'
          aria-hidden={true}
          role='none'
          title={getVariantDescription(variant, total)}
        >
          <Text className={classes.title}>{getVariantTitle(variant)}</Text>
          <Flex justify='end' align='center' gap='4'>
            {getVariantIcon(variant)}
            <Text className={classes.count} data-testid='action-card-count'>
              {total}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex gap='2' className={clsx(classes.actions)} direction='column'>
        {children}
      </Flex>
    </Card>
  );
};

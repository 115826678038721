import React, { FunctionComponent } from 'react';
import { Flex, Select } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormSelect').create(() => ({
  trigger: {
    display: 'flex',
    width: '100%',
    height: '40px',
    padding: '8px 12px',
    backgroundColor: 'var(--bg-inputs)',
    cursor: 'pointer',
	boxShadow: 'inset 0 0 0 1px var(--input-border-color)',
    '&:disabled': {
      cursor: 'not-allowed'
    },
    '& > span': {
      color: 'var(--font-primary-color)'
    }
  }
}));

export const FormSelect: FunctionComponent<
  Select.RootProps & {
    triggerProps?: Select.TriggerProps;
    items: { label: string; value: string; icon?: React.ReactNode }[];
  }
> = ({ items, triggerProps, ...props }) => {
  const { classes } = useStyles();

  return (
    <Select.Root {...props}>
      <Select.Trigger
        className={classes.trigger}
        data-testid='form-select-trigger'
        {...triggerProps}
      />
      <Select.Content color='gray' variant='soft'>
        {items.map(({ label, value, icon }, i) => (
          <Select.Item key={i} value={value}>
            <Flex gap='2' align='center'>
              {icon}
              {label}
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

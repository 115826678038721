import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Container } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { NAVBAR_HEIGHT } from 'app/constants/app';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import Routes from 'app/modules/Routes';

import { AuthErrorPage, UnauthorisedPage } from '../Errors';
import { Navigation } from '../Navigation';
import { PageLoadingIndicator } from '../PageLoadingIndicator';
import { WhatsNewDialog } from '../WhatsNew';

const useStyles = tss.withName('AppLayout').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  main: {
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,

    '&>*': {
      height: '100%',
      maxWidth: '100%',
      width: '100%'
    }
  },
  skipContent: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    zIndex: 2,
    borderRadius: '0',

    '&:focus': {
      width: '100%',
      height: 'auto',
      left: 0,
      top: 0
    }
  },
  srOnly: {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute',
    width: '1px !important',
    whiteSpace: 'nowrap !important'
  }
}));

export const AppLayout: FunctionComponent = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const {
    isLoading: isUseLoggedInUserLoading,
    error: useLoggedInUserError,
    user
  } = useLoggedInUser();
  const {
    isLoading: isUseSelectedCustomerLoading,
    error: useSelectedCustomerError,
    selectedCustomer
  } = useSelectedCustomer();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) containerRef.current.focus();
  }, [pathname, selectedCustomer]);

  return (
    <div className={classes.container}>
      <span className={classes.srOnly} tabIndex={-1} ref={containerRef}></span>
      <div role='region' aria-label='Skip links'>
        <Button
          type='button'
          className={classes.skipContent}
          aria-label='Skip to main content'
          onClick={() => {
            mainContentRef.current?.focus(); // <-- focus on element
          }}
        >
          Skip to main content
        </Button>
      </div>
      <Navigation />
      <Container className={classes.main} ref={mainContentRef} tabIndex={-1}>
        {(isUseLoggedInUserLoading || isUseSelectedCustomerLoading) && <PageLoadingIndicator />}

        {(useLoggedInUserError || useSelectedCustomerError) && <AuthErrorPage />}

        {!(isUseLoggedInUserLoading || isUseSelectedCustomerLoading) &&
        !(useLoggedInUserError || useSelectedCustomerError) ? (
          user ? (
            <Routes />
          ) : (
            <UnauthorisedPage />
          )
        ) : (
          <div />
        )}
      </Container>
      <WhatsNewDialog />
    </div>
  );
};

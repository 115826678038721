import { FunctionComponent } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormLabel').create(() => ({
  label: {
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: 1,
    fontWeight: '700',
    marginBottom: '8px'
  }
}));

export const FormLabel: FunctionComponent<
  FormPrimitive.FormLabelProps & { defaultLabel?: boolean }
> = ({ children, defaultLabel = false, ...props }) => {
  const { classes } = useStyles();

  return (
    <>
      {defaultLabel ? (
        <label {...props} className={classes.label}>{children}</label>
      ) : (
        <FormPrimitive.Label {...props} className={classes.label}>
          {children}
        </FormPrimitive.Label>
      )}
    </>
  );
};

import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from '@phosphor-icons/react';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { format } from 'date-fns';
import { tss } from 'tss-react';

import {
  ColumnSortButton,
  DeleteTableRow,
  PageLoadingIndicator,
  StatusBadge
} from 'app/components';
import {
  USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES,
  USER_MANAGEMENT_ADD_MODIFY_DELETE_THREATSCAPE_USER_REQUIRED_ROLES
} from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { SortBy, SortOrder, USER_TYPES } from 'app/constants/users';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useDeleteUserMutation } from 'app/queries/useUserManagementQueries';
import { PagedResponse, User } from 'app/types';

import { UserStatusChange } from './UserStatusChange';

const useStyles = tss
  .withName('UsersTable')
  .withNestedSelectors<'showOnHover'>()
  .create(({ classes }) => ({
    table: {
      width: '100%',
      border: '1px solid var(--slate-200)',
      borderRadius: '6px',
      padding: '16px 8px',
      gap: '8px',
      backgroundColor: 'var(--white)',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
      borderSpacing: '0'
    },
    rowText: {
      color: 'var(--font-primary-color)',
      fontSize: '14px',
      lineHeight: 1.71
    },
    rowTextEmail: {
      color: 'var(--font-secondary-color)',
      fontSize: '12px',
      lineHeight: 1.667,
      overflow: 'hidden',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis'
    },
    columnLabel: {
      color: 'var(--font-primary-color)',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5
    },
    row: {
      '&:hover, &:focus-within': {
        backgroundColor: 'var(--bg-default)'
      },
      [`&:hover .${classes.showOnHover}, &:focus .${classes.showOnHover}, &:focus-within .${classes.showOnHover}`]:
        {
          opacity: 1
        },
      '& > td': {
        borderTop: '1px solid var(--slate-200)',
        padding: '4px 8px 8px'
      }
    },
    rowHeader: {
      '& > th': {
        padding: '0 8px 8px'
      }
    },
    rowDisabled: {
      opacity: 0.6,
      pointerEvents: 'none'
    },
    cell: {
      textAlign: 'left'
    },
    showOnHover: {
      opacity: 0,
      '&:hover, &:focus': {
        opacity: 1
      }
    },
    iconButton: {
      margin: 0,
      color: 'var(--font-primary-color)',
      cursor: 'pointer',
      padding: '6px',
      display: 'flex',
      borderRadius: '4px',
      '&:hover, &:focus': {
        backgroundColor: 'var(--slate-200)'
      }
    },
    deleteIconButton: {
      color: 'var(--red-a5)'
    }
  }));

type UsersTableProps = {
  data: User[];
  pageSize: number;
  sortState: { sortBy: SortBy; sortOrder: SortOrder };
  setSortState: React.Dispatch<React.SetStateAction<{ sortBy: SortBy; sortOrder: SortOrder }>>;
  isLoading: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<PagedResponse<User>, Error>>;
};

export const UsersTable = ({
  data,
  pageSize,
  sortState,
  setSortState,
  isLoading,
  refetch
}: UsersTableProps) => {
  const { classes } = useStyles();
  const { user, isAllowed } = useLoggedInUser();
  const [currentPage, setCurrentPage] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [deleteRow, setDeleteRow] = useState<string | null>(null);

  const { mutate: deleteUser } = useDeleteUserMutation();

  const handleSortChange = useMemo(
    () => (column: SortBy) => {
      setSortState(prev => {
        const newSortOrder = prev.sortBy === column && prev.sortOrder === 'asc' ? 'desc' : 'asc';
        return { sortBy: column, sortOrder: newSortOrder };
      });
      setCurrentPage(0);
    },
    [setSortState]
  );

  const isAllowedCRUD = useMemo(
    () => isAllowed(USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES),
    [isAllowed]
  );

  const isAllowedCRUDThreatscape = useMemo(
    () => isAllowed(USER_MANAGEMENT_ADD_MODIFY_DELETE_THREATSCAPE_USER_REQUIRED_ROLES),
    [isAllowed]
  );

  const defaultColumns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => (
          <ColumnSortButton
            label='Name'
            sortState={sortState}
            handleSort={() => handleSortChange('name')}
            value={'name'}
          />
        ),
        cell: ({ row }) => (
          <Flex direction='column'>
            <Text as='span' className={classes.rowText}>
              {row.original.name}
            </Text>
            <Text as='span' className={classes.rowTextEmail}>
              {row.original.email}
            </Text>
          </Flex>
        )
      },
      {
        id: 'customer',
        cell: ({ row }) => {
          return (
            row.original.customer !== null && (
              <Text as='span' className={classes.rowText}>
                {row.original.customer?.name}
                {row.original.customer?.enabled === false && ' (disabled)'}
              </Text>
            )
          );
        },
        header: () => (
          <ColumnSortButton
            label='Customer'
            sortState={sortState}
            handleSort={() => handleSortChange('customer.name')}
            value={'customer.name'}
          />
        )
      },
      {
        accessorFn: row => row.role,
        id: 'role',

        cell: ({ row }) => {
          const role = row.original.role.charAt(0) + row.original.role.slice(1).toLowerCase();
          return (
            <Text as='span' className={classes.rowText}>
              {role}
            </Text>
          );
        },
        header: () => (
          <ColumnSortButton
            label='Role'
            sortState={sortState}
            handleSort={() => handleSortChange('role')}
            value={'role'}
          />
        )
      },
      {
        accessorFn: row => row.createdAt,
        id: 'joined',
        cell: ({ row }) => {
          return (
            <Text as='span' className={classes.rowText}>
              {format(row.original.createdAt, 'yyyy-MM-dd')}
            </Text>
          );
        },
        header: () => (
          <ColumnSortButton
            label='Joined'
            sortState={sortState}
            handleSort={() => handleSortChange('createdAt')}
            value={'createdAt'}
          />
        )
      },
      {
        accessorFn: row => row.lastActivity,
        id: 'lastActivity',
        cell: ({ row }) => {
          return (
            <Text as='span' className={classes.rowText}>
              {row.original.lastActivity ? format(row.original.lastActivity, 'yyyy-MM-dd') : null}
            </Text>
          );
        },
        header: () => (
          <ColumnSortButton
            label='Last activity'
            sortState={sortState}
            handleSort={() => handleSortChange('lastActivity')}
            value={'lastActivity'}
          />
        )
      },
      {
        accessorKey: 'status',
        header: () => (
          <ColumnSortButton
            label='Status'
            sortState={sortState}
            handleSort={() => handleSortChange('status')}
            value={'status'}
          />
        ),
        cell: ({ row }) => <StatusBadge status={row.original.status} />
      },
      {
        id: 'action',
        header: () => <Text className={classes.columnLabel}>Action</Text>,
        cell: ({ row }) => (
          <>
            {/**
             * IF row has an enable customer
             *  - OR is a threatscape user
             *
             * AND
             *
             * IF row is a threatscape user and logged in user is allowed to crud threatscape
             *  - OR row is not a threatscape user and logged in user is allowed crud
             *
             * THEN show some actions
             */}
            {((row.original.customer && row.original.customer.enabled) ||
              row.original.type === USER_TYPES.THREATSCAPE) &&
              ((row.original.type === USER_TYPES.THREATSCAPE && isAllowedCRUDThreatscape) ||
                (isAllowedCRUD && row.original.type !== USER_TYPES.THREATSCAPE)) && (
                <Flex gap='2'>
                  <Link
                    title='Edit User'
                    to={`/${URLS.ADMIN_CENTRE_USERS_EDIT.replace(':id', `${row.original.id}`)}`}
                    className={classes.iconButton}
                  >
                    <Pencil size={20} weight='fill' role='none' />
                  </Link>
                  {user?.id !== row.original.id && (
                    <UserStatusChange
                      className={classes.showOnHover}
                      user={row.original}
                      onSuccess={() => refetch()}
                    />
                  )}
                  {user?.id !== row.original.id && (
                    <IconButton
                      className={clsx(
                        classes.iconButton,
                        classes.deleteIconButton,
                        classes.showOnHover
                      )}
                      variant='ghost'
                      color='gray'
                      data-testid={`delete-user-${row.id}`}
                      onClick={() => setDeleteRow(row.id)}
                      title='Delete User'
                    >
                      <Trash size={20} weight='fill' role='none' />
                    </IconButton>
                  )}
                </Flex>
              )}
          </>
        )
      }
    ],
    [
      sortState,
      handleSortChange,
      classes,
      isAllowedCRUDThreatscape,
      isAllowedCRUD,
      user?.id,
      refetch
    ]
  );

  const table = useReactTable({
    data: data,
    columns: defaultColumns,
    state: {
      pagination: { pageIndex: currentPage, pageSize },
      columnVisibility: {
        ...columnVisibility,
        customer: user?.type !== USER_TYPES.CUSTOMER
      }
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  return (
    <table className={classes.table}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className={classes.rowHeader}>
            {headerGroup.headers.map(header => (
              <th key={header.id} className={classes.cell} scope='col'>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {isLoading ? (
        <tbody>
          <tr>
            <td colSpan={table.getAllColumns().length}>
              <PageLoadingIndicator />
            </td>
          </tr>
        </tbody>
      ) : table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map(row => (
          <tbody key={row.id}>
            <tr
              className={clsx(
                classes.row,
                row.original.customer &&
                  row.original.customer.enabled === false &&
                  classes.rowDisabled
              )}
              aria-disabled={
                row.original.customer && row.original.customer.enabled === false ? 'true' : 'false'
              }
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={classes.cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {deleteRow === row.id && (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <DeleteTableRow
                    buttonText='Delete user'
                    message='User will be permanently deleted. This action cannot be undone.'
                    onDelete={() =>
                      deleteUser(row.original.id, { onSuccess: () => setDeleteRow(null) })
                    }
                    onCancel={() => setDeleteRow(null)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        ))
      ) : (
        <tbody>
          <tr className={classes.row}>
            <td colSpan={table.getAllColumns().length}>
              <Text className={classes.rowText}>No users found</Text>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

import { FunctionComponent } from 'react';

import { useFetchSolutions } from 'app/queries/useSolutionQueries';
import { CommonFilterProps } from 'app/types/filters';

import { SolutionIcon } from '../Controls/SolutionIcon';

import { FilterSection } from './common/FilterSection';
import { MultiSelectFilter } from './common/MultiSelectFilter';

export const SolutionsFilter: FunctionComponent<CommonFilterProps> = ({
  defaultValue,
  ...props
}) => {
  const { data, isLoading } = useFetchSolutions(true);

  return (
    <FilterSection label='Solutions' labelId='solutions-filter-label' disableCollapse={true}>
      <MultiSelectFilter
        inputId='solutions-filter'
        items={(data || []).map(val => ({
          value: `${val.id}`,
          label: val.name,
          icon: <SolutionIcon solution={val} />
        }))}
        name='solutionIds'
        aria-labelledby='solutions-filter-label'
        isDisabled={isLoading}
        {...props}
        emptyText='All solutions'
        data-testid='solutions-filter-select'
        defaultValue={
          data
            ?.filter(val => (defaultValue || []).includes(`${val.id}`))
            .map(solution => ({
              value: `${solution.id}`,
              label: solution.name,
              icon: <SolutionIcon solution={solution} />
            })) ?? []
        }
      />
    </FilterSection>
  );
};

import { FunctionComponent, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SignOut, UserCircle } from '@phosphor-icons/react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Button, IconButton } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { useAuthContext, useIsAuthenticated } from 'app/auth';
import {
  CUSTOMER_MANAGEMENT_REQUIRED_ROLES,
  MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES,
  USER_MANAGEMENT_REQUIRED_ROLES
} from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useWhatsNewContext } from 'app/hooks/useWhatsNew';

import { SwitchCustomer } from '../SwitchCustomer';

const useStyles = tss.withName('NavigationRightContent').create(() => ({
  list: {
    display: 'flex',
    justifyContent: 'end',
    padding: '8px 40px',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    gap: '32px'
  },

  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: 'auto'
  },

  menu: {
    listStyle: 'none',
    width: '140px',
    padding: '4px',
    margin: '0'
  },

  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4px',
    padding: '6px 8px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: 1.43,
    '&:focus': {
      boxShadow: '0 0 0 2px var(--slate-7)'
    },
    '&:hover': {
      backgroundColor: 'var(--slate-3)'
    }
  },

  menuItemActive: {
    backgroundColor: 'var(--slate-3)'
  },

  menuItemText: {
    backgroundColor: 'transparent',
    lineHeight: '1',
    color: 'var(--font-primary-color)',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  viewportContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    top: '100%',
    left: '0',
    perspective: '2000px'
  },
  viewport: {
    position: 'relative',
    width: '100%',
    maxWidth: 'var(--radix-navigation-menu-viewport-width)',
    height: 'var(--radix-navigation-menu-viewport-height)',
    marginTop: '-20px',
    marginRight: '24px',
    overflow: 'hidden',
    backgroundColor: 'var(--white)',
    border: '1px solid var(--slate-200)',
    borderRadius: '6px',
    boxShadow: '0px 4px 6px 0px #00000017'
  }
}));

export const NavigationRightContent: FunctionComponent = () => {
  const { classes } = useStyles();
  const { instance } = useAuthContext();
  const isAuthenticated = useIsAuthenticated();
  const { isAllowed, isThreatscapeUser } = useLoggedInUser();
  const location = useLocation();
  const { setIsWhatsNewOpen } = useWhatsNewContext();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const defaultAdminUrl = useMemo(
    () =>
      isAllowed(CUSTOMER_MANAGEMENT_REQUIRED_ROLES)
        ? URLS.ADMIN_CENTRE_CUSTOMERS
        : URLS.ADMIN_CENTRE_USERS,
    [isAllowed]
  );

  const hasAdminCentreAccess = useMemo(
    () =>
      isAllowed(USER_MANAGEMENT_REQUIRED_ROLES) ||
      isAllowed(CUSTOMER_MANAGEMENT_REQUIRED_ROLES) ||
      isAllowed(MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );

  const isAdminPage = useMemo(
    () => location.pathname.includes(URLS.ADMIN_ROOT),
    [location.pathname]
  );

  const handleOpenWhatsNew = () => {
    setIsWhatsNewOpen(true);
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <NavigationMenu.List className={classes.list} data-testid='navigation-right-content'>
            <NavigationMenu.Item>
              {isThreatscapeUser && !isAdminPage && <SwitchCustomer />}
            </NavigationMenu.Item>
            <NavigationMenu.Item>
              <NavigationMenu.Trigger asChild data-testid='user-menu-trigger'>
                <IconButton
                  variant='ghost'
                  color='gray'
                  highContrast
                  aria-label='User menu'
                  id='user-menu'
                  aria-controls='user-menu'
                >
                  <UserCircle width='24' height='24' weight='fill' role='none' />
                </IconButton>
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className={classes.content} id='user-menu-navigation-content'>
                <ul className={classes.menu} data-testid='user-menu-list'>
                  {hasAdminCentreAccess && (
                    <NavigationMenu.Link asChild>
                      <li
                        className={clsx(classes.menuItem, isAdminPage && classes.menuItemActive)}
                        data-testid='user-menu-admin-item'
                      >
                        <Link
                          className={classes.menuItemText}
                          to={defaultAdminUrl}
                          aria-label='Admin centre'
                        >
                          Admin centre
                        </Link>
                      </li>
                    </NavigationMenu.Link>
                  )}
                  <NavigationMenu.Link asChild>
                    <li className={classes.menuItem}>
                      <Button
                        className={classes.menuItemText}
                        onClick={handleOpenWhatsNew}
                        variant='ghost'
                        data-testid='user-menu-whats-new-item'
                        aria-label='What&lsquo;s new'
                        title='What&lsquo;s new'
                      >
                        What&lsquo;s new
                      </Button>
                    </li>
                  </NavigationMenu.Link>
                  <NavigationMenu.Link asChild>
                    <li className={classes.menuItem} data-testid='user-menu-logout-item'>
                      <Button
                        className={classes.menuItemText}
                        onClick={handleLogout}
                        variant='ghost'
                        aria-label='Logout'
                        title='Logout'
                      >
                        <span>Logout</span>
                        <SignOut width='16' height='16' weight='fill' role='none' />
                      </Button>
                    </li>
                  </NavigationMenu.Link>
                </ul>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          </NavigationMenu.List>
          <div className={classes.viewportContainer}>
            <NavigationMenu.Viewport className={classes.viewport} />
          </div>
        </>
      )}
    </>
  );
};

import { FunctionComponent } from 'react';

import { useFetchNISPrinciples } from 'app/queries/useNISPrinciplesQueries';
import { CommonFilterProps } from 'app/types/filters';

import { FilterSection } from './common/FilterSection';
import { MultiSelectFilter } from './common/MultiSelectFilter';

export const NISPrinciplesFilter: FunctionComponent<CommonFilterProps> = ({
  defaultValue,
  onChange
}) => {
  const { data, isLoading } = useFetchNISPrinciples(true);

  return (
    <FilterSection
      label='NIS principles'
      labelId='nis-principles-filter-label'
      disableCollapse={true}
    >
      <MultiSelectFilter
        inputId='nis-principles-filter'
        items={(data || []).map(val => ({
          value: `${val.id}`,
          label: val.name
        }))}
        name='nisPrincipleIds'
        aria-labelledby='nis-principles-filter-label'
        isDisabled={isLoading}
        onChange={onChange}
        emptyText='All NIS principles'
        data-testid='nis-principles-filter-select'
        defaultValue={
          data
            ?.filter(val => (defaultValue || []).includes(`${val.id}`))
            .map(nisPrinciple => ({
              value: `${nisPrinciple.id}`,
              label: nisPrinciple.name
            })) ?? []
        }
      />
    </FilterSection>
  );
};

import { FunctionComponent, useMemo } from 'react';

import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { ReactComponent as CompletedIcon } from 'assets/status-icons/completed.svg';
import { ReactComponent as CompletedThisMonthIcon } from 'assets/status-icons/completed-this-month.svg';
import { ReactComponent as InProgressIcon } from 'assets/status-icons/in-progress.svg';
import { ReactComponent as NotRequiredIcon } from 'assets/status-icons/not-required.svg';
import { ReactComponent as NotStartedIcon } from 'assets/status-icons/not-started.svg';

type DashboardStatusIconProps = {
  status: DASHBOARD_STATUS;
  size?: number;
};

export const DashboardStatusIcon: FunctionComponent<DashboardStatusIconProps> = ({
  status,
  size = 24
}) => {
  const [SVGComponent, alt, color] = useMemo(() => {
    switch (status) {
      case DASHBOARD_STATUS.IN_PROGRESS:
        return [InProgressIcon, 'In progress', 'var(--rag-amber)'];
      case DASHBOARD_STATUS.COMPLETED:
        return [CompletedIcon, 'Completed', 'var(--rag-dark-green)'];
      case DASHBOARD_STATUS.COMPLETED_THIS_MONTH:
        return [CompletedThisMonthIcon, 'Completed (this month)', 'var(--rag-light-green)'];
      case DASHBOARD_STATUS.NOT_REQUIRED:
        return [NotRequiredIcon, 'Not required', 'var(--rag-dull)'];
      case DASHBOARD_STATUS.NOT_STARTED:
      default:
        return [NotStartedIcon, 'Not started', 'var(--rag-sky)'];
    }
  }, [status]);

  return (
    <SVGComponent
      role='img'
      aria-label={alt}
      title={alt}
      color={color}
      data-testid='control-status-icon'
      width={size}
      height={size}
    />
  );
};

import { compareDesc } from 'date-fns';

import { CONTROL_STATUS_VALUES } from 'app/constants/controls';
import { DASHBOARD_STATUS_VALUES } from 'app/constants/dashboard';
import { FILTER_PROP_NAMES, YES_NO_VALUE } from 'app/constants/filters';
import { CustomerControl } from 'app/types/controls';
import { DashboardControl } from 'app/types/dashboard';
import { CommonFilters } from 'app/types/filters';

/**
 * URLSearchParams
 */

export const updateSearchParamsWithFilter = (
  urlParams = new URLSearchParams(),
  prop: FILTER_PROP_NAMES,
  value?: string | string[]
) => {
  // remove the old value from params
  urlParams.delete(prop);

  // add the new value (if we have one)
  if (value) {
    if (Array.isArray(value)) {
      value.forEach(val => urlParams.append(prop, val));
    } else {
      urlParams.append(prop, value);
    }
  }

  // return the updated params
  return urlParams;
};

export const buildSearchParamsFromFilters = (
  filters: CommonFilters,
  urlParams = new URLSearchParams()
) => {
  urlParams.delete(FILTER_PROP_NAMES.FREETEXT);
  if (filters.freeText) urlParams.append(FILTER_PROP_NAMES.FREETEXT, filters.freeText);

  urlParams.delete(FILTER_PROP_NAMES.IMPACT);
  if (filters.impact)
    filters.impact.forEach(val => urlParams.append(FILTER_PROP_NAMES.IMPACT, val));

  urlParams.delete(FILTER_PROP_NAMES.INITIAL_FINDINGS);
  if (filters.initialFindings)
    filters.initialFindings.forEach(val =>
      urlParams.append(FILTER_PROP_NAMES.INITIAL_FINDINGS, val)
    );

  urlParams.delete(FILTER_PROP_NAMES.LICENSED);
  if (filters.licensed)
    filters.licensed.forEach(val => urlParams.append(FILTER_PROP_NAMES.LICENSED, val));

  urlParams.delete(FILTER_PROP_NAMES.RISK);
  if (filters.risk) filters.risk.forEach(val => urlParams.append(FILTER_PROP_NAMES.RISK, val));

  urlParams.delete(FILTER_PROP_NAMES.SOLUTIONS);
  if (filters.solutionIds)
    filters.solutionIds.forEach(val => urlParams.append(FILTER_PROP_NAMES.SOLUTIONS, val));

  urlParams.delete(FILTER_PROP_NAMES.NIS_PRINCIPLES);
  if (filters.nisPrinciplesIds)
    filters.nisPrinciplesIds.forEach(val =>
      urlParams.append(FILTER_PROP_NAMES.NIS_PRINCIPLES, val)
    );

  urlParams.delete(FILTER_PROP_NAMES.STATUSES);
  if (filters.statuses)
    filters.statuses.forEach(val => urlParams.append(FILTER_PROP_NAMES.STATUSES, val));

  return urlParams;
};

export const buildFiltersFromSearchParams = (
  urlParams: URLSearchParams,
  dashboardStatuses = false
): CommonFilters => {
  const impact = urlParams.getAll(FILTER_PROP_NAMES.IMPACT);
  const initialFindings = urlParams.getAll(FILTER_PROP_NAMES.INITIAL_FINDINGS);
  const licensed = urlParams.getAll(FILTER_PROP_NAMES.LICENSED);
  const nisPrinciplesIds = urlParams.getAll(FILTER_PROP_NAMES.NIS_PRINCIPLES);
  const risk = urlParams.getAll(FILTER_PROP_NAMES.RISK);
  const solutionIds = urlParams.getAll(FILTER_PROP_NAMES.SOLUTIONS);

  const statusFromParams = urlParams.getAll(FILTER_PROP_NAMES.STATUSES);
  const statusValues = dashboardStatuses ? DASHBOARD_STATUS_VALUES : CONTROL_STATUS_VALUES;

  const statuses = statusFromParams
    .map(paramStatus => (statusValues as string[]).find(val => val === paramStatus))
    .filter(val => !!val) as string[];

  return {
    freeText: urlParams.get(FILTER_PROP_NAMES.FREETEXT) || undefined,
    impact: impact.length ? impact : undefined,
    initialFindings: initialFindings.length ? initialFindings : undefined,
    licensed: licensed.length ? licensed : undefined,
    nisPrinciplesIds: nisPrinciplesIds.length ? nisPrinciplesIds : undefined,
    risk: risk.length ? risk : undefined,
    solutionIds: solutionIds.length ? solutionIds : undefined,
    statuses: statuses.length ? statuses : undefined
  };
};

/**
 * Filtering
 */

const FILTER_FUNCS = {
  freeText: (control: CustomerControl | DashboardControl, { freeText }: CommonFilters) =>
    `${control.externalControlId}`.includes(freeText!.toLocaleLowerCase()) ||
    control.name.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()) ||
    control.controlNotes?.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()) ||
    control.initialFindings?.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()),
  initialFindings: (
    control: CustomerControl | DashboardControl,
    { initialFindings }: CommonFilters
  ) =>
    (initialFindings || []).includes(control.initialFindings ? YES_NO_VALUE.YES : YES_NO_VALUE.NO),
  licensed: (control: CustomerControl | DashboardControl, { licensed }: CommonFilters) =>
    (licensed || []).includes(control.licenced ? YES_NO_VALUE.YES : YES_NO_VALUE.NO),
  impact: (control: CustomerControl | DashboardControl, { impact }: CommonFilters) =>
    (impact || []).includes(control.businessRisk?.toLocaleUpperCase() ?? ''),
  nisPrinciplesIds: (
    control: CustomerControl | DashboardControl,
    { nisPrinciplesIds }: CommonFilters
  ) => (control.nisPrinciples || []).some(v => (nisPrinciplesIds || []).includes(`${v.id}`)),
  risk: (control: CustomerControl | DashboardControl, { risk }: CommonFilters) =>
    (risk || []).includes(control.securityRisk?.toLocaleUpperCase() ?? ''),
  statuses: (control: CustomerControl | DashboardControl, { statuses }: CommonFilters) =>
    (statuses || []).includes(control.status ?? ''),
  solutionIds: (control: CustomerControl | DashboardControl, { solutionIds }: CommonFilters) =>
    (solutionIds || []).includes(`${control.solution?.id}`)
};

const checkControlMatchesFilters = (
  control: CustomerControl | DashboardControl,
  prop: keyof CommonFilters,
  filters: CommonFilters
) => (filters[prop] ? !!FILTER_FUNCS[prop](control, filters) : true);

export const filterControls = (controls: CustomerControl[], filters: CommonFilters) => {
  if (controls.length === 0) return controls;

  const filteredData = controls.filter(
    control =>
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.FREETEXT, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.LICENSED, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.NIS_PRINCIPLES, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters)
  );

  return filteredData;
};

export const filterDashboardControls = (controls: DashboardControl[], filters: CommonFilters) => {
  if (controls.length === 0) return controls;

  const filteredData = controls.filter(
    control =>
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.LICENSED, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.NIS_PRINCIPLES, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
      checkControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters)
  );

  return filteredData;
};

/**
 * Sorting
 */
const convertRiskToNumber = (risk?: string) => {
  if (risk) {
    if (risk.toLocaleLowerCase() === 'high') return 0;
    if (risk.toLocaleLowerCase() === 'medium') return 1;
    if (risk.toLocaleLowerCase() === 'low') return 2;
  }
  return 3;
};

export const sortControls = (controls: CustomerControl[], sort: string) => {
  const [sortProperty, sortDir] = sort.split(',');

  if (sortProperty === 'securityRisk' || sortProperty === 'businessRisk') {
    return sortDir === 'asc'
      ? controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) < convertRiskToNumber(b[sortProperty]) ? -1 : 1
        )
      : controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) > convertRiskToNumber(b[sortProperty]) ? -1 : 1
        );
  }

  if (sortProperty === 'lastUpdatedAt') {
    return controls.sort((a, b) => {
      if (!a.lastUpdatedAt) return 1;
      if (!b.lastUpdatedAt) return -1;

      return compareDesc(new Date(a.lastUpdatedAt), new Date(b.lastUpdatedAt));
    });
  }

  // default is by externalControlId
  return controls.sort((a, b) => (a.externalControlId < b.externalControlId ? -1 : 1));
};

import { Fragment, FunctionComponent, useMemo } from 'react';
import { X } from '@phosphor-icons/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Button, Flex, Spinner, Text, VisuallyHidden } from '@radix-ui/themes';
import { keyframes, tss } from 'tss-react';

import { NAVBAR_HEIGHT } from 'app/constants/app';
import { useWhatsNewContext } from 'app/hooks/useWhatsNew';
import { useFetchWhatsNewList } from 'app/queries/useWhatsNewQueries';

import { WhatsNewItem } from './WhatsNewItem';

const contentShow = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const useStyles = tss.withName('WhatsNew').create(() => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    padding: '16px 8px 16px 16px',
    gap: '8px',
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--lime-a1)',
    marginRight: 0,
    boxShadow:
      'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: '106px',
    right: '32px',
    maxWidth: '450px',
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    zIndex: 100,
    boxSizing: 'border-box',
    // height of whats new dialog is 100vh - navbar height - padding from top and bottom(2*16px)
    height: `calc(100vh - ${NAVBAR_HEIGHT}px - 32px)`
  },
  dialogTitle: {
    color: 'var(--lime-a9)',
    fontSize: '16px',
    lineHeight: 1.4,
    fontWeight: 700,
    margin: 0
  },
  dialogClose: {
    border: '1px solid var(--lime-a3)',
    color: 'var(--lime-a7)',
    padding: '8px',
    cursor: 'pointer',
    '--button-ghost-padding-x': 0
  },
  date: {
    color: 'var(--lime-a7)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.43,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  group: {
    '&:not(:first-of-type)': {
      borderTop: '1px solid var(--lime-a9)',
      paddingTop: '8px'
    }
  },
  loadMore: {
    color: 'var(--lime-a7)',
    textDecoration: 'underline',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  error: {
    color: 'var(--lime-a7)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.43
  },
  dataContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: '8px',
    scrollbarGutter: 'stable',
    //specific style for Firefox
    '@supports (-moz-appearance: none)': {
      paddingRight: '16px'
    }
  }
}));

export const WhatsNewDialog: FunctionComponent = () => {
  const { classes } = useStyles();
  const { isWhatsNewOpen, setIsWhatsNewOpen } = useWhatsNewContext();

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isError } =
    useFetchWhatsNewList(isWhatsNewOpen);

  const hasData = useMemo(() => data && data.pages.find(val => val.content.length), [data]);

  return (
    <DialogPrimitive.Root open={isWhatsNewOpen}>
      <DialogPrimitive.Content
        className={classes.dialogContent}
        role='dialog'
        data-testid='whats-new-dialog'
        id='whats-new-dialog'
        onPointerDownOutside={() => setIsWhatsNewOpen(false)}
        aria-describedby='whats-new-dialog-description'
      >
        <Flex gap='3' justify='between' align='center'>
          <DialogPrimitive.Title className={classes.dialogTitle}>
            What&lsquo;s new
          </DialogPrimitive.Title>
          <VisuallyHidden asChild>
            <DialogPrimitive.Description id='whats-new-dialog-description'>
              Whats new dialog giving the info about newest changes in controls
            </DialogPrimitive.Description>
          </VisuallyHidden>

          <Button
            variant='ghost'
            className={classes.dialogClose}
            onClick={() => setIsWhatsNewOpen(false)}
            data-testid='whats-new-close-button'
            aria-label='Close What&lsquo;s new dialog'
            title='Close What&lsquo;s new dialog'
          >
            <X size={16} role='none' />
          </Button>
        </Flex>
        {/*  */}
        <Flex direction='column' gap='3' className={classes.dataContainer}>
          {hasData && data && (
            <Flex direction='column' gap='2' data-testid='bulletins-list'>
              {data?.pages.map((group, i) => (
                <Fragment key={i}>
                  {group.content.map(whatsNewItem => (
                    <Flex
                      key={whatsNewItem.date}
                      direction='column'
                      gap='2'
                      className={classes.group}
                    >
                      <Text className={classes.date}>{whatsNewItem.date}</Text>
                      <Flex direction='column' gap='4'>
                        {whatsNewItem.bulletins.map(bulletin => (
                          <WhatsNewItem whatsNewItem={bulletin} key={bulletin.id} />
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </Fragment>
              ))}
            </Flex>
          )}
          {isFetching && (
            <Flex direction='column' align='center'>
              <Spinner size='3' />
            </Flex>
          )}
          {(isError || !hasData) && (
            <Flex direction='column' align='center'>
              <p className={classes.error}>No bulletins here yet.</p>
            </Flex>
          )}
          {hasNextPage && (
            <Button
              variant='ghost'
              className={classes.loadMore}
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage || isFetching}
              aria-label={isFetchingNextPage ? 'Loading more...' : 'Load more'}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          )}
        </Flex>
      </DialogPrimitive.Content>
    </DialogPrimitive.Root>
  );
};

import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  OptionProps
} from 'react-select';
import { CaretDown, Check, X } from '@phosphor-icons/react';
import { Flex } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

const useStyles = tss.withName('MultiSelectFilter').create(() => ({
  trigger: {
    '& > div:first-of-type': {
      display: 'flex',
      width: '100%',
      height: '40px',
      backgroundColor: 'var(--bg-inputs)',
      cursor: 'pointer',
	  borderColor: 'var(--input-border-color)',
	  borderRadius: '6px'
    }
  },
  selectedOptions: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '8px'
  },
  selectedOptionButton: {
    padding: '0',
    backgroundColor: 'var(--bg-primary)',
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    border: 'none',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: 1.67,
    width: '100%',

    '& > * > svg': {
      flex: 'none'
    }
  },
  selectedOptionButtonInner: {
    maxWidth: '170px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  dropdown: {
    border: '1px solid var(--slate-200)',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1) !important',
    overflow: 'hidden',
    backgroundColor: 'var(--bg-dropdown)',
    width: 'max-content !important'
  },
  menuList: {
    padding: '8px !important',
    '::-webkit-scrollbar': {
      width: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--slate-200)',
      borderRadius: '4px'
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'var(--white)'
    }
  },
  option: {
    borderRadius: '6px',
    position: 'relative',
    color: 'var(--font-primary-color) !important',
    cursor: 'pointer !important',
    padding: '4px 24px !important',
    fontSize: '14px !important',
    display: 'flex !important',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default) !important'
    }
  },
  optionSelected: {
    background: 'var(--white) !important',
    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default) !important'
    }
  },
  optionFocus: {
    backgroundColor: 'var(--bg-default) !important'
  },
  check: {
    position: 'absolute',
    left: '6px'
  },
  control: {
    svg: {
      fill: 'var(--font-primary-color) !important'
    }
  },
  placeholder: {
    color: 'var(--font-primary-color) !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

type SelectOption = {
  value: string;
  label: string;
  icon?: ReactNode;
};

const Option = (props: OptionProps<SelectOption, true, GroupBase<SelectOption>>) => {
  const { classes } = useStyles();
  const { isSelected } = props;

  return (
    <components.Option
      {...props}
      cx={state => {
        return clsx(
          classes.option,
          isSelected && classes.optionSelected,
          state['option--is-focused'] && classes.optionFocus // this was the tricky bit - focus needs to be another class
        );
      }}
      aria-label={`${isSelected ? 'Deselect' : 'Select'} ${props.data.label}`}
    >
      <Flex gap='2' align='center'>
        {isSelected && <Check size={14} className={classes.check} role='none' />}
        {props.data.icon}
        {props.data.label}
      </Flex>
    </components.Option>
  );
};

const DropdownIndicator = (
  props: DropdownIndicatorProps<SelectOption, true, GroupBase<SelectOption>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown size={14} />
    </components.DropdownIndicator>
  );
};

type MultiSelectFilterProps = {
  name: string;
  isDisabled: boolean;
  items: SelectOption[];
  onChange?: (selectedValues: string[]) => void;
  defaultValue?: SelectOption[];
  emptyText?: string;
  inputId: string;
  'data-testid'?: string;
};

export const MultiSelectFilter: FunctionComponent<MultiSelectFilterProps> = ({
  items,
  name,
  isDisabled,
  onChange,
  defaultValue,
  emptyText = 'None',
  inputId,
  ...props
}) => {
  const { classes } = useStyles();

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  const handleSelectChange = (options: MultiValue<SelectOption> | null) => {
    const newSelectedOptions = options
      ? options.map(opt => ({ value: opt.value, label: opt.label, icon: opt.icon }))
      : [];
    setSelectedOptions(newSelectedOptions);

    if (onChange) {
      onChange(newSelectedOptions.map(opt => opt.value));
    }
  };

  const handleRemoveOption = (optionValue: string) => {
    const newSelectedOptions = selectedOptions.filter(opt => opt.value !== optionValue);
    setSelectedOptions(newSelectedOptions);

    if (onChange) {
      onChange(newSelectedOptions.map(opt => opt.value));
    }
  };

  useEffect(() => {
    if (defaultValue) {
      // if the defaultValue and the selectOptions are NOT the same
      // then overwrite the selectedOption with the defaultValue
      if (
        defaultValue.length !== selectedOptions.length ||
        !defaultValue.every(item => selectedOptions.includes(item))
      ) {
        setSelectedOptions([...defaultValue]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    document.getElementById(inputId)!.setAttribute('aria-controls', '');
  }, [inputId]);

  return (
    <Flex direction='column' gap='2' data-testid={props['data-testid']}>
      <Select
        value={selectedOptions}
        isMulti
        name={name}
        options={items}
        isDisabled={isDisabled}
        className={classes.trigger}
        onChange={handleSelectChange}
        isSearchable={false}
        placeholder={selectedOptions.length > 0 ? `${selectedOptions.length} selected` : emptyText}
        hideSelectedOptions={false}
        inputId={inputId}
        isClearable={false}
        components={{
          Option,
          DropdownIndicator,
          IndicatorSeparator: null
        }}
        classNames={{
          menuList: () => classes.menuList,
          menu: () => classes.dropdown,
          placeholder: () => classes.placeholder,
          control: () => classes.control
        }}
        controlShouldRenderValue={false}
        menuPosition='fixed'
        {...props}
        defaultValue={selectedOptions}
      />
      {!!selectedOptions?.length && (
        <div className={classes.selectedOptions} data-testid='selected-options'>
          {selectedOptions.map(option => (
            <button
              key={option.value}
              className={classes.selectedOptionButton}
              onClick={() => handleRemoveOption(option.value)}
              title={`Remove option ${option.label}`}
              aria-label={`Remove option ${option.label}`}
            >
              <Flex gap='2' align='center'>
                {option.icon}
                <span className={classes.selectedOptionButtonInner}>{option.label}</span>
                <X size={16} role='none' />
              </Flex>
            </button>
          ))}
        </div>
      )}
    </Flex>
  );
};

import { FunctionComponent, useMemo } from 'react';

import { CONTROL_STATUS, CONTROL_STATUS_LABELS } from 'app/constants/controls';
import { ReactComponent as CompletedIcon } from 'assets/status-icons/completed.svg';
import { ReactComponent as InProgressIcon } from 'assets/status-icons/in-progress.svg';
import { ReactComponent as NotRequiredIcon } from 'assets/status-icons/not-required.svg';
import { ReactComponent as NotStartedIcon } from 'assets/status-icons/not-started.svg';

type ControlStatusIconProps = {
  status: CONTROL_STATUS;
  size?: number;
};

export const ControlStatusIcon: FunctionComponent<ControlStatusIconProps> = ({
  status,
  size = 12
}) => {
  const [SVGComponent, alt, color] = useMemo(() => {
    switch (status) {
      case CONTROL_STATUS.IN_PROGRESS:
        return [InProgressIcon, CONTROL_STATUS_LABELS.IN_PROGRESS, 'var(--rag-amber)'];
      case CONTROL_STATUS.COMPLETED:
        return [CompletedIcon, CONTROL_STATUS_LABELS.COMPLETED, 'var(--rag-dark-green)'];
      case CONTROL_STATUS.NOT_REQUIRED:
        return [NotRequiredIcon, CONTROL_STATUS_LABELS.NOT_REQUIRED, 'var(--rag-dull)'];
      case CONTROL_STATUS.NOT_STARTED:
      default:
        return [NotStartedIcon, CONTROL_STATUS_LABELS.NOT_STARTED, 'var(--rag-sky)'];
    }
  }, [status]);

  return (
    <SVGComponent
      role='img'
      aria-label={alt}
      title={alt}
      color={color}
      data-testid='control-status-icon'
      width={size}
      height={size}
    />
  );
};

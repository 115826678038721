import { FunctionComponent } from 'react';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('LicenseTag').create(() => ({
  container: {
    color: 'var(--font-secondary-color)',
    padding: 'var(--space-1)',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '12px'
  }
}));

type LicenseTagProps = {
  licenced: boolean;
};

export const LicenseTag: FunctionComponent<LicenseTagProps> = ({ licenced }) => {
  const { classes } = useStyles();

  return (
    <Flex gap='1' align='center' className={classes.container}>
      {licenced ? (
        <CheckCircle size={16} weight='fill' color='var(--rag-light-green)' role='none' />
      ) : (
        <XCircle size={16} weight='fill' color='var(--rag-red)' role='none' />
      )}
      {licenced ? 'Licensed' : 'Not licensed'}
    </Flex>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { IconButton, TextField } from '@radix-ui/themes';
import { tss } from 'tss-react';
import { useDebounce } from 'use-debounce';

const useStyles = tss.withName('FreeTextFilter').create(() => ({
  textfield: {
    boxShadow: 'none',
    '&>input': {
      border: '1px solid var(--input-border-color)',
      borderLeft: 'none'
    }
  },
  textfieldIcon: {
    paddingLeft: '16px',
    paddingRight: '8px',
    border: '1px solid var(--input-border-color)',
    borderRight: 'none',
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px'
  },
  clearSearchIcon: {
    border: '1px solid var(--input-border-color)',
    borderLeft: 'none',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px'
  },
  inputFilled: {
    '&>input': {
      borderRight: 'none'
    }
  }
}));

const MILLIS_FOR_DEBOUNCE = 250;

type FreeTextFilterProps = {
  defaultValue?: string;
  onChange: (value: string) => void;
  className?: string;
};
export const FreeTextFilter: FunctionComponent<FreeTextFilterProps> = ({
  defaultValue = '',
  onChange,
  className
}) => {
  const { classes, cx } = useStyles();

  const [freeText, setFreeText] = useState(defaultValue);

  const [debouncedValue] = useDebounce(freeText, MILLIS_FOR_DEBOUNCE);

  const handleClearFreeText = () => {
    setFreeText('');
    onChange('');
  };

  useEffect(() => {
    // trim any white space before/after
    onChange(freeText.trim().replace(/\s\s+/g, ' '));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <TextField.Root
      className={cx(classes.textfield, className, defaultValue && classes.inputFilled)}
      data-testid='freetext-filter'
      placeholder='Search...'
      size='3'
      value={freeText}
      onChange={e => setFreeText(e.target.value)}
      aria-label='Search input'
      role='searchbox'
    >
      <TextField.Slot className={classes.textfieldIcon}>
        <MagnifyingGlass size={16} role='none' />
      </TextField.Slot>
      {defaultValue && (
        <TextField.Slot className={classes.clearSearchIcon}>
          <IconButton
            size='1'
            variant='ghost'
            color='gray'
            onClick={handleClearFreeText}
            data-testid='freetext-filter-reset'
            aria-label='Clear search input'
          >
            <X size={16} role='none' />
          </IconButton>
        </TextField.Slot>
      )}
    </TextField.Root>
  );
};

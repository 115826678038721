import { Fragment, FunctionComponent, useMemo } from 'react';
import { Button, Flex, Spinner } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { useFetchCustomerControlNotes } from 'app/queries/useControlQueries';
import { ControlNote, CustomerControl } from 'app/types/controls';

import { AddNote } from './AddNote';
import { Note } from './Note';

const useStyles = tss.withName('NotesList').create(() => ({
  container: {
    color: 'var(--font-primary-color)',
    padding: '8px 0 0'
  },
  error: {
    fontSize: '14px',
    lineHeight: 1.7,
    fontStyle: 'italic',
    color: 'var(--font-secondary-color)',
    margin: 0
  },
  loadMore: {
    cursor: 'pointer',
    margin: 0
  }
}));

type NotesListProps = {
  control: CustomerControl;
  onNotesChange: (newNote: ControlNote) => void;
};

export const NotesList: FunctionComponent<NotesListProps> = ({ control, onNotesChange }) => {
  const { classes } = useStyles();

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isError } =
    useFetchCustomerControlNotes(true, control.externalControlId);

  const hasData = useMemo(() => data && data.pages.find(val => val.content.length), [data]);

  return (
    <Flex className={classes.container} direction='column' gap='4'>
      <AddNote control={control} onNotesChange={onNotesChange} />
      {hasData && data && (
        <Flex className={classes.container} direction='column' gap='2' data-testid='notes-list'>
          {data?.pages.map((group, i) => (
            <Fragment key={i}>
              {group.content.map(note => (
                <Note key={note.id} note={note} />
              ))}
            </Fragment>
          ))}
        </Flex>
      )}
      {isFetching && (
        <Flex direction='column' align='center'>
          <Spinner size='3' />
        </Flex>
      )}
      {(isError || !hasData) && (
        <Flex direction='column' align='center' data-testid='empty-notes'>
          <p className={classes.error}>No notes here yet.</p>
        </Flex>
      )}
      {hasNextPage && (
        <Button
          variant='ghost'
          className={classes.loadMore}
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage || isFetching}
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load more'}
        </Button>
      )}
    </Flex>
  );
};

import { FunctionComponent } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { TextField } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormInput').create(() => ({
  control: {
    height: '36px',
    boxShadow: 'none',
    backgroundColor: 'var(--bg-inputs)',
    width: '100%',

    '&:has(> input[readonly])': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      color: 'var(--font-primary-color)',
      pointerEvents: 'none'
    },

    '&>input': {
      padding: '8px 12px',
      textIndent: 'initial',
      border: '1px solid var(--input-border-color)',
      borderRadius: '6px',

      '&[data-invalid="true"]': {
        borderColor: 'var(--rag-red)',
        backgroundColor: 'var(--red-a3)'
      },

      '&[readonly]': {
        color: 'var(--font-primary-color)',
        WebkitTextFillColor: 'var(--font-primary-color)',
        pointerEvents: 'none',
        border: 'none'
      }
    }
  }
}));

export const FormInput: FunctionComponent<FormPrimitive.FormControlProps> = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <FormPrimitive.Control asChild type='text' {...props} className={classes.control}>
      <TextField.Root />
    </FormPrimitive.Control>
  );
};

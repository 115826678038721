import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowCounterClockwise, Warning } from '@phosphor-icons/react';
import { Callout, Flex, IconButton, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { FormField, FormLabel, FormPanel, FormTextArea } from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { URLS } from 'app/constants/routes';
import {
  WHATS_NEW_BULLETIN_DEFAULT_TEXT,
  WHATS_NEW_STORED_MASTER_CONTROL_INFO
} from 'app/constants/whatsNew';
import { useWhatsNewContext } from 'app/hooks/useWhatsNew';
import { useCreateWhatsNewBulletinMutation } from 'app/queries/useWhatsNewQueries';
import { BulletinData, findControlDifferences } from 'app/utils/bulletin-utils';
import { getMessageFromError } from 'app/utils/error-utils';

const useStyles = tss.withName('PageLoadingIndicator').create(() => ({
  container: {
    maxWidth: '664px',
    width: '100%'
  },
  messageSuccessful: {
    display: 'flex',
    backgroundColor: 'var(--green-a4)',
    color: 'var(--green-a10)',
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700,
    borderRadius: '6px'
  },
  resetButton: {
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    padding: '0',
    '--icon-button-ghost-padding': 0
  }
}));

type StoredMasterControlInfo = {
  externalControlId: number;
  action: 'create' | 'edit';
  newControlData?: BulletinData;
  oldControlData?: BulletinData;
};

export const CreateMasterControlBulletin: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showNotesError, setShowNotesError] = useState(false);
  const [bulletinValue, setBulletinValue] = useState(WHATS_NEW_BULLETIN_DEFAULT_TEXT);
  const [storedMasterControlInfo, setStoredMasterControlInfo] = useState<StoredMasterControlInfo>();

  const { mutate, isPending, isError, error } = useCreateWhatsNewBulletinMutation();
  const { setIsWhatsNewOpen } = useWhatsNewContext();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const bulletin = formData.get('bulletin') as string;

    mutate(
      {
        externalControlId: storedMasterControlInfo!.externalControlId,
        content: bulletin
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ADMIN_CENTRE_CONTROLS}`);
          setIsWhatsNewOpen(true);
          localStorage.removeItem(WHATS_NEW_STORED_MASTER_CONTROL_INFO);
        }
      }
    );
  };

  const findDifferencesMessageCallback = useCallback(() => {
    const bulletin = findControlDifferences(
      storedMasterControlInfo!.newControlData!,
      storedMasterControlInfo!.oldControlData!
    );
    setBulletinValue(bulletin);
    bulletin.trim() === '' ? setShowNotesError(true) : setShowNotesError(false);
  }, [storedMasterControlInfo]);

  const handleCancel = () => {
    navigate(`/${URLS.ADMIN_CENTRE_CONTROLS}`);
    localStorage.removeItem(WHATS_NEW_STORED_MASTER_CONTROL_INFO);
  };

  useEffect(() => {
    const storedInfo = localStorage.getItem(WHATS_NEW_STORED_MASTER_CONTROL_INFO);
    if (storedInfo) {
      setStoredMasterControlInfo(JSON.parse(storedInfo));
    } else {
      navigate(`/${URLS.ADMIN_CENTRE_CONTROLS}`);
    }
  }, [navigate]);

  useEffect(() => {
    if (storedMasterControlInfo?.action === 'edit') {
      setBulletinValue(
        findControlDifferences(
          storedMasterControlInfo.newControlData!,
          storedMasterControlInfo.oldControlData!
        )
      );
      findDifferencesMessageCallback();
    }
  }, [findDifferencesMessageCallback, storedMasterControlInfo]);

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.CONTROLS}>
      <FormPanel
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        className={classes.container}
        cancelBtnText='Discard bulletin'
        saveBtnText='Publish to "What‘s New"'
        isSubmitting={isPending}
        isDisabled={showNotesError}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='control-form-error'>
            <Callout.Icon>
              <Warning />
            </Callout.Icon>
            <Callout.Text>Unable to create control bulletin.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}
        <Flex direction='column' gap='4'>
          <Text className={classes.messageSuccessful}>
            {`Control successfully ${storedMasterControlInfo?.action === 'create' ? 'created' : 'saved'}`}
          </Text>
          <FormField name='bulletin' data-testid='new-bulletin-content'>
            <Flex justify='between' align='center'>
              <FormLabel id='control-bulletin-label' htmlFor='control-bulletin'>
                Control bulletin
              </FormLabel>
              <IconButton
                size='2'
                variant='ghost'
                type='button'
                onClick={() => {
                  storedMasterControlInfo?.action === 'create'
                    ? setBulletinValue(WHATS_NEW_BULLETIN_DEFAULT_TEXT)
                    : findDifferencesMessageCallback();
                }}
                className={classes.resetButton}
                aria-label='Reset to autogenerated text'
                title='Reset to autogenerated text'
                data-testid='new-bulletin-content-reset'
              >
                <ArrowCounterClockwise role='none' />
              </IconButton>
            </Flex>
            <FormTextArea
              id='control-bulletin'
              name='bulletin'
              onChange={val => {
                setShowNotesError(val.target.value.trim().length === 0);
                setBulletinValue(val.target.value);
              }}
              value={bulletinValue}
              disabled={isPending}
              data-testid='new-bulletin-content-input'
              rows={5}
            />
          </FormField>
        </Flex>
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};

import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from '@phosphor-icons/react';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { format } from 'date-fns';
import { tss } from 'tss-react';

import {
  ColumnSortButton,
  DeleteTableRow,
  PageLoadingIndicator,
  StatusBadge
} from 'app/components';
import { LICENCES_LABELS, SortBy, SortOrder } from 'app/constants/customers';
import { CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { USER_STATUS } from 'app/constants/users';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useDeleteCustomerMutation } from 'app/queries/useCustomerManagementQueries';
import { Customer, PagedResponse } from 'app/types';

import { CustomerStatusChange } from './CustomerStatusChange';

const useStyles = tss
  .withName('CustomersTable')
  .withNestedSelectors<'showOnHover'>()
  .create(({ classes }) => ({
    table: {
      width: '100%',
      border: '1px solid var(--slate-200)',
      borderRadius: '6px',
      padding: '16px 8px',
      gap: '8px',
      backgroundColor: 'var(--white)',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
      borderSpacing: '0'
    },
    rowText: {
      color: 'var(--font-primary-color)',
      fontSize: '14px',
      lineHeight: 1.71
    },
    badge: {
      padding: '4px',
      fontSize: '12px',
      fontWeight: 900,
      letterSpacing: '1.2px',
      lineHeight: 1.667,
      borderRadius: '4px'
    },
    badgeActive: {
      color: 'var(--green-a10)',
      backgroundColor: 'var(--green-a4)'
    },
    badgeRegistered: {
      color: 'var(--amber-a8)',
      backgroundColor: 'var(--amber-a4)'
    },
    badgeDisabled: {
      color: 'var(--font-primary-color)',
      backgroundColor: 'var(--slate-200)'
    },
    columnLabel: {
      color: 'var(--font-primary-color)',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5
    },
    row: {
      '&:hover, &:focus-within': {
        backgroundColor: 'var(--bg-default)'
      },
      [`&:hover .${classes.showOnHover}, &:focus .${classes.showOnHover}, &:focus-within .${classes.showOnHover}`]:
        {
          opacity: 1
        },
      '& > td': {
        borderTop: '1px solid var(--slate-200)',
        padding: '8px 8px 16px'
      }
    },
    rowHeader: {
      '& > th': {
        padding: '0 8px 8px'
      }
    },
    cell: {
      textAlign: 'left'
    },
    showOnHover: {
      opacity: 0,
      '&:hover, &:focus': {
        opacity: 1
      }
    },
    iconButton: {
      margin: 0,
      color: 'var(--font-primary-color)',
      cursor: 'pointer',
      padding: '6px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      '&:hover, &:focus': {
        backgroundColor: 'var(--slate-200)'
      }
    },
    deleteIconButton: {
      color: 'var(--red-a5)'
    }
  }));

type CustomersTableProps = {
  data: Customer[];
  pageSize: number;
  sortState: { sortBy: SortBy; sortOrder: SortOrder };
  setSortState: React.Dispatch<React.SetStateAction<{ sortBy: SortBy; sortOrder: SortOrder }>>;
  isLoading: boolean;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<PagedResponse<Customer>, Error>>;
};

export const CustomersTable = ({
  data,
  pageSize,
  sortState,
  setSortState,
  isLoading,
  refetch
}: CustomersTableProps) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteRow, setDeleteRow] = useState<string | null>(null);

  const { mutate: deleteCustomer } = useDeleteCustomerMutation();

  const handleSortChange = useMemo(
    () => (column: SortBy) => {
      setSortState(prev => {
        const newSortOrder = prev.sortBy === column && prev.sortOrder === 'asc' ? 'desc' : 'asc';
        return { sortBy: column, sortOrder: newSortOrder };
      });
      setCurrentPage(0);
    },
    [setSortState]
  );

  const isAllowedCRUD = useMemo(
    () => isAllowed(CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES),
    [isAllowed]
  );

  const defaultColumns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => (
          <ColumnSortButton
            label='Name'
            sortState={sortState}
            handleSort={() => handleSortChange('name')}
            value={'name'}
          />
        ),
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {row.original.name}
          </Text>
        )
      },
      {
        accessorKey: 'm365Licence',
        header: () => (
          <ColumnSortButton
            label='M365'
            sortState={sortState}
            handleSort={() => handleSortChange('m365Licence')}
            value={'m365Licence'}
          />
        ),
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {LICENCES_LABELS[row.original.m365Licence]}
          </Text>
        )
      },
      {
        accessorKey: 'createdAt',
        header: () => (
          <ColumnSortButton
            label='Joined'
            sortState={sortState}
            handleSort={() => handleSortChange('createdAt')}
            value={'createdAt'}
          />
        ),
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {format(row.original.createdAt, 'yyyy-MM-dd')}
          </Text>
        )
      },
      {
        accessorKey: 'enabled',
        header: () => (
          <ColumnSortButton
            label='Status'
            sortState={sortState}
            handleSort={() => handleSortChange('enabled')}
            value={'enabled'}
          />
        ),
        cell: ({ row }) => (
          <StatusBadge status={row.original.enabled ? USER_STATUS.ACTIVE : USER_STATUS.DISABLED} />
        )
      },
      {
        id: 'action',
        header: () => <Text className={classes.columnLabel}>Action</Text>,
        cell: ({ row }) => (
          <>
            {isAllowedCRUD && (
              <Flex gap='2'>
                <Link
                  title='Edit customer'
                  to={`/${URLS.ADMIN_CENTRE_CUSTOMERS_EDIT.replace(':id', `${row.original.id}`)}`}
                  className={classes.iconButton}
                >
                  <Pencil size={20} weight='fill' role='none' />
                </Link>
                <CustomerStatusChange
                  className={classes.showOnHover}
                  customer={row.original}
                  onSuccess={() => refetch()}
                />
                <IconButton
                  className={clsx(
                    classes.iconButton,
                    classes.deleteIconButton,
                    classes.showOnHover
                  )}
                  variant='ghost'
                  color='gray'
                  data-testid={`delete-customer-${row.id}`}
                  onClick={() => setDeleteRow(row.id)}
                  title='Delete customer'
                >
                  <Trash size={20} weight='fill' role='none' />
                </IconButton>
              </Flex>
            )}
          </>
        )
      }
    ],
    [sortState, handleSortChange, classes, isAllowedCRUD, refetch]
  );

  const table = useReactTable({
    data: data,
    columns: defaultColumns,
    state: {
      pagination: { pageIndex: currentPage, pageSize }
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  return (
    <table className={classes.table}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className={classes.rowHeader}>
            {headerGroup.headers.map(header => (
              <th key={header.id} className={classes.cell} scope='col'>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {isLoading ? (
        <tbody>
          <tr>
            <td colSpan={table.getAllColumns().length}>
              <PageLoadingIndicator />
            </td>
          </tr>
        </tbody>
      ) : table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map(row => (
          <tbody key={row.id}>
            <tr className={classes.row}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={classes.cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {deleteRow === row.id && (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <DeleteTableRow
                    buttonText='Delete Customer'
                    message='Customer will be permanently deleted. This action cannot be undone.'
                    onDelete={() =>
                      deleteCustomer(row.original.id, { onSuccess: () => setDeleteRow(null) })
                    }
                    onCancel={() => setDeleteRow(null)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        ))
      ) : (
        <tbody>
          <tr className={classes.row}>
            <td colSpan={table.getAllColumns().length}>
              <Text className={classes.rowText}>No customers found</Text>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

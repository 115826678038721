import { FormEvent, FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout, Flex } from '@radix-ui/themes';

import { SolutionIcon } from 'app/components';
import {
  FormCheckboxGroup,
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormMultiSelect,
  FormPanel,
  FormRadioGroup,
  FormSelect,
  FormTextArea
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { LICENCE_TYPE_OPTIONS, RISK_OPTIONS } from 'app/constants/controls';
import { RISK_VALUE } from 'app/constants/dashboard';
import { URLS } from 'app/constants/routes';
import { WHATS_NEW_STORED_MASTER_CONTROL_INFO } from 'app/constants/whatsNew';
import { useCreateMasterControlMutation } from 'app/queries/useMasterControlsQueries';
import { useFetchNISPrinciples } from 'app/queries/useNISPrinciplesQueries';
import { useFetchSolutions } from 'app/queries/useSolutionQueries';
import { getMessageFromError } from 'app/utils/error-utils';

export const CreateMasterControl: FunctionComponent = () => {
  const navigate = useNavigate();

  const [showSecurityRiskError, setShowSecurityRiskError] = useState(false);
  const [showBusinessImpactError, setShowBusinessImpactError] = useState(false);
  const [showSolutionError, setShowSolutionError] = useState(false);
  const [m365Licences, setM365Licences] = useState<string[]>([]);
  const [showM365LicenceError, setShowM365LicenceError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showNotesError, setShowNotesError] = useState(false);
  const [showVersionError, setShowVersionError] = useState(false);
  const [showExternalControlIdError, setShowExternalControlIdError] = useState(false);
  const [showNisPrinciplesError, setShowNisPrinciplesError] = useState(false);

  const { data: solutionsData, isLoading: isSolutionsDataLoading } = useFetchSolutions(true);
  const { data: nisPrinciplesData, isLoading: isNisPrinciplesDataLoading } =
    useFetchNISPrinciples(true);

  const { mutate, isPending, isError, error } = useCreateMasterControlMutation();

  const handleM365LicenceChange = (val: string[]) => {
    setM365Licences(val);
    setShowM365LicenceError(val.length === 0);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const externalControlId = formData.get('externalControlId');
    const name = formData.get('name');
    const notes = formData.get('notes');
    const version = formData.get('version');
    const nisPrincipleIds = formData
      .getAll('nisPrincipleIds')
      .map(id => Number(id))
      .filter(id => id !== 0);
    const solutionId = formData.get('solutionId');
    const isM365LicenceSelected = m365Licences.length > 0;
    const businessRisk = formData.get('businessRisk');
    const securityRisk = formData.get('securityRisk');

    const externalControlIdError = !externalControlId;
    const nameError = !name;
    const notesError = !notes;
    const versionError = !version;
    const nisPrincipleError = nisPrincipleIds.length === 0;
    const solutionError = !solutionId;
    const m365LicenceError = !isM365LicenceSelected;
    const businessImpactError = !businessRisk;
    const securityRiskError = !securityRisk;

    setShowM365LicenceError(m365LicenceError);
    setShowSolutionError(solutionError);
    setShowBusinessImpactError(businessImpactError);
    setShowExternalControlIdError(externalControlIdError);
    setShowSecurityRiskError(securityRiskError);
    setShowNameError(nameError);
    setShowNotesError(notesError);
    setShowVersionError(versionError);
    setShowNisPrinciplesError(nisPrincipleError);

    if (
      externalControlIdError ||
      nameError ||
      notesError ||
      versionError ||
      nisPrincipleError ||
      solutionError ||
      m365LicenceError ||
      businessImpactError ||
      securityRiskError
    ) {
      return;
    }

    mutate(
      {
        control: {
          externalControlId: Number(externalControlId),
          name: name as string,
          controlNotes: notes as string,
          securityRisk: securityRisk as RISK_VALUE,
          businessRisk: businessRisk as RISK_VALUE,
          nisPrincipleIds: nisPrincipleIds,
          m365Licences: m365Licences,
          solutionId: Number(solutionId),
          version: version as string
        }
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ADMIN_CENTRE_CONTROLS_BULLETIN_CREATE}`);
          localStorage.setItem(
            WHATS_NEW_STORED_MASTER_CONTROL_INFO,
            JSON.stringify({ externalControlId: Number(externalControlId), action: 'create' })
          );
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_CONTROLS}`);
    localStorage.removeItem(WHATS_NEW_STORED_MASTER_CONTROL_INFO);
  };

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.CONTROLS}>
      <FormPanel
        heading='New Control'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='control-form-error'>
            <Callout.Icon>
              <Warning role='none' />
            </Callout.Icon>
            <Callout.Text>Unable to create control.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}
        <Flex gap='6'>
          <Flex direction='column' gap='4' minWidth='200px'>
            <FormField name='externalControlId' data-testid='control-id-field'>
              <FormLabel>Control ID</FormLabel>
              <FormInput
                name='externalControlId'
                type='number'
                max={9999999}
                onChange={val => setShowExternalControlIdError(val.target.value.length === 0)}
                disabled={isPending}
              />
              <FormMessage match='valueMissing' forceMatch={showExternalControlIdError}>
                This field is required
              </FormMessage>
              <FormMessage match='rangeOverflow'>Value must be 7 digits or less</FormMessage>
            </FormField>
            <FormField name='name' data-testid='control-name-field'>
              <FormLabel>Name</FormLabel>
              <FormInput
                name='name'
                onChange={val => setShowNameError(val.target.value.length === 0)}
                disabled={isPending}
              />
              <FormMessage match='valueMissing' forceMatch={showNameError}>
                This field is required
              </FormMessage>
            </FormField>
            <FormField name='notes' data-testid='control-notes-field'>
              <FormLabel>Control notes</FormLabel>
              <FormTextArea
                name='notes'
                onChange={val => setShowNotesError(val.target.value.length === 0)}
                disabled={isPending}
              />
              <FormMessage match='valueMissing' forceMatch={showNotesError}>
                This field is required
              </FormMessage>
            </FormField>
            <FormField name='version' data-testid='control-version-field'>
              <FormLabel>Version number</FormLabel>
              <FormInput
                name='version'
                onChange={val => setShowVersionError(val.target.value.length === 0)}
                disabled={isPending}
              />
              <FormMessage match='valueMissing' forceMatch={showVersionError}>
                This field is required
              </FormMessage>
            </FormField>
          </Flex>
          <Flex direction='column' gap='4' minWidth='200px'>
            <FormPrimitive.Field name='m365Licences' data-testid='control-m365-licence-field'>
              <FormLabel id='control-m365-licence-label' defaultLabel>
                M365 licence
              </FormLabel>
              <FormCheckboxGroup
                id='control-m365-licence'
                aria-labelledby='control-m365-licence-label'
                name='m365Licences'
                value={m365Licences}
                disabled={isPending}
                onValueChange={handleM365LicenceChange}
                items={LICENCE_TYPE_OPTIONS}
              />
              <FormMessage match='valueMissing' forceMatch={showM365LicenceError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>

            <FormPrimitive.Field name='securityRisk' data-testid='control-security-risk-field'>
              <FormLabel id='control-security-risk-label' defaultLabel>
                Risk
              </FormLabel>
              <FormRadioGroup
                id='control-security-risk'
                aria-labelledby='control-security-risk-label'
                name='securityRisk'
                disabled={isPending}
                onInvalid={() => setShowSecurityRiskError(true)}
                items={RISK_OPTIONS}
                onValueChange={val => setShowSecurityRiskError(!val)}
                labelVariant='risk'
              />
              <FormMessage match='valueMissing' forceMatch={showSecurityRiskError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>
            <FormPrimitive.Field name='businessRisk' data-testid='control-business-risk-field'>
              <FormLabel id='control-business-risk-label' defaultLabel>
                Impact
              </FormLabel>
              <FormRadioGroup
                id='control-business-risk'
                aria-labelledby='control-business-risk-label'
                name='businessRisk'
                disabled={isPending}
                onInvalid={() => setShowBusinessImpactError(true)}
                items={RISK_OPTIONS}
                onValueChange={val => setShowBusinessImpactError(!val)}
                labelVariant='risk'
              />
              <FormMessage match='valueMissing' forceMatch={showBusinessImpactError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>
          </Flex>
          <Flex direction='column' gap='4' minWidth='200px'>
            <FormPrimitive.Field name='solutionId' data-testid='control-solution-field'>
              <FormLabel id='control-solution-label' htmlFor='control-solution'>
                Solution
              </FormLabel>
              <FormSelect
                name='solutionId'
                triggerProps={{
                  'aria-labelledby': 'control-solution-label',
                  'aria-controls': '',
                  'aria-autocomplete': 'list',
                  placeholder: 'None',
                  id: 'control-solution'
                }}
                items={(solutionsData || []).map(val => ({
                  value: `${val.id}`,
                  label: val.name,
                  icon: <SolutionIcon solution={val} />
                }))}
                disabled={isSolutionsDataLoading || isPending}
                onValueChange={val => setShowSolutionError(val.length === 0)}
              />
              <FormMessage match='valueMissing' forceMatch={showSolutionError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>
            <FormPrimitive.Field name='nisPrincipleIds' data-testid='control-nis-priciples-field'>
              <FormLabel id='control-nis-priciples-label' htmlFor='control-nis-priciples'>
                NIS principles
              </FormLabel>
              <FormMultiSelect
                inputId='control-nis-priciples'
                items={(nisPrinciplesData || []).map(val => ({
                  value: `${val.id}`,
                  label: val.name
                }))}
                name='nisPrincipleIds'
                isDisabled={isNisPrinciplesDataLoading || isPending}
                onChange={() => setShowNisPrinciplesError(false)}
              />
              <FormMessage match='valueMissing' forceMatch={showNisPrinciplesError}>
                This field is required
              </FormMessage>
            </FormPrimitive.Field>
          </Flex>
        </Flex>
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};

import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import {
  DashboardStatusFilter,
  ImpactFilter,
  InitialFindingsFilter,
  LicensedFilter,
  NISPrinciplesFilter,
  RiskFilter,
  SolutionsFilter
} from 'app/components/Filters';
import { NAVBAR_HEIGHT } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { CommonFilters } from 'app/types';

const useStyles = tss.withName('FilterPanel').create(() => ({
  container: {
    flex: '1 1 25%',
    position: 'sticky',
    top: 0,
    background: 'var(--white)',
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    padding: '8px 16px 16px',
    borderRadius: 'var(--radius-4)',
    zIndex: 1,

    overflow: 'auto',
    height: 'fit-content',
    // height is: height of screen - navbar height - padding from top and bottom of page wrapper
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px - 32px)`
  },
  filterContainer: {
    padding: '0 8px',

    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '16px 0'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '16px 0 0'
    }
  },

  title: {
    fontSize: '18px',
    lineHeight: 1.55,
    fontWeight: 700
  }
}));

type FilterPanelProps = {
  filters: CommonFilters;
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
};

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({ filters, onFilterChange }) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' className={classes.container} flexGrow='1' data-testid='filters-panel'>
      <Flex direction='column' gap='2'>
        <Text className={classes.title}>Filters</Text>
      </Flex>
      <Flex direction='column' className={classes.filterContainer}>
        <DashboardStatusFilter
          defaultValue={filters.statuses}
          defaultAsOpen={!!filters.statuses}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.STATUSES, value)}
        />
        <LicensedFilter
          defaultValue={filters.licensed}
          defaultAsOpen={!!filters.licensed}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.LICENSED, value)}
        />
        <RiskFilter
          defaultValue={filters.risk}
          defaultAsOpen={!!filters.risk}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.RISK, value)}
        />
        <ImpactFilter
          defaultValue={filters.impact}
          defaultAsOpen={!!filters.impact}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.IMPACT, value)}
        />
        <InitialFindingsFilter
          defaultValue={filters.initialFindings}
          defaultAsOpen={!!filters.initialFindings}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.INITIAL_FINDINGS, value)}
        />
        <SolutionsFilter
          defaultValue={filters.solutionIds}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.SOLUTIONS, value)}
        />
        <NISPrinciplesFilter
          defaultValue={filters.nisPrinciplesIds}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.NIS_PRINCIPLES, value)}
        />
      </Flex>
    </Flex>
  );
};

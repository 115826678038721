import { Text } from '@radix-ui/themes';
import { TooltipProps } from 'recharts';
import { tss } from 'tss-react';

const useStyles = tss.withName('ProgressReportTooltip').create(() => ({
  tooltip: {
    background: 'var(--bg-inputs)',
    color: 'var(--font-primary-color)',
    padding: '2px 8px',
    borderRadius: '4px',
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  },
  tooltipText: {
    color: 'var(--font-primary-color)',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: 1.667
  }
}));

export const ProgressReportTooltip = ({
  active,
  payload
}: TooltipProps<number, string>): JSX.Element | null => {
  const { classes } = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        <Text
          className={classes.tooltipText}
        >{`${payload[0].payload.current} (${payload[0].payload.total} total)`}</Text>
      </div>
    );
  }

  return null;
};

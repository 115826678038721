import { FormEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout } from '@radix-ui/themes';

import { PageLoadingIndicator } from 'app/components';
import {
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormPanel,
  FormSelect
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { URLS } from 'app/constants/routes';
import {
  USER_ROLE_OPTIONS,
  USER_ROLES,
  USER_STATUS,
  USER_STATUS_OPTIONS
} from 'app/constants/users';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useFetchUser, useUpdateUserMutation } from 'app/queries/useUserManagementQueries';
import { UpdateUserRequest } from 'app/types/users';
import { getMessageFromError } from 'app/utils/error-utils';

export const UpdateUser: FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user: loggedInUser, isThreatscapeUser } = useLoggedInUser();

  const [showCustomer, setShowCustomer] = useState(isThreatscapeUser);
  const [user, setUser] = useState<UpdateUserRequest | undefined>();

  const { data: originalUser, isFetching: isOriginalUserLoading } = useFetchUser(
    !!params.id,
    params.id || ''
  );

  const { mutate, isPending, isError, error } = useUpdateUserMutation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const userRequest: UpdateUserRequest = {
      name: formData.get('name') as string,
      email: formData.get('email') as string,
      role: formData.get('role') as USER_ROLES,
      status: formData.get('status') as USER_STATUS
    };

    if (isEditingSelf && originalUser) {
      userRequest.role = originalUser.role;
      userRequest.status = originalUser.status;
    }

    mutate(
      {
        userId: originalUser!.id,
        user: userRequest
      },
      {
        onSuccess: () => {
          setUser(undefined);
          navigate(`/${URLS.ADMIN_CENTRE_USERS}`);
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_USERS}`);
  };

  const isEditingSelf = useMemo(
    () => loggedInUser && originalUser && loggedInUser.id === originalUser.id,
    [loggedInUser, originalUser]
  );

  useEffect(() => {
    if (!isOriginalUserLoading && originalUser) {
      setShowCustomer(isThreatscapeUser && !!originalUser.customer);
      setUser({
        name: originalUser.name,
        email: originalUser.email,
        status: originalUser.status,
        role: originalUser.role
      });
    }
  }, [isOriginalUserLoading, isThreatscapeUser, originalUser]);

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.USERS}>
      <FormPanel
        heading='Edit User'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
        isDisabled={!originalUser}
      >
        {isOriginalUserLoading && <PageLoadingIndicator />}
        {!isOriginalUserLoading && isError && (
          <Callout.Root color='red' size='1' data-testid='user-form-error'>
            <Callout.Icon>
              <Warning role='none' />
            </Callout.Icon>
            <Callout.Text>Unable to update user.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}

        {originalUser && user && (
          <>
            <FormField name='name' data-testid='user-name-field'>
              <FormLabel>Name</FormLabel>
              <FormInput
                name='name'
                required
                disabled={isPending || !originalUser}
                defaultValue={user.name}
              />
              <FormMessage match='valueMissing'>This field is required</FormMessage>
            </FormField>

            <FormField name='email' data-testid='user-email-field'>
              <FormLabel>Email</FormLabel>
              <FormInput
                name='email'
                type='email'
                required
                disabled={isPending || !originalUser}
                defaultValue={user.email}
                pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
              />
              <FormMessage match='valueMissing'>This field is required</FormMessage>
              <FormMessage match='patternMismatch'>Please enter a valid email</FormMessage>
            </FormField>

            {showCustomer && originalUser.customer && (
              <FormPrimitive.Field name='customerId' data-testid='user-customerId-field'>
                <FormLabel id='user-customer-label'>Customer</FormLabel>
                <FormInput name='customerId' readOnly defaultValue={originalUser.customer.name} />
              </FormPrimitive.Field>
            )}

            <FormPrimitive.Field name='role' data-testid='user-role-field'>
              <FormLabel id='user-role-label' htmlFor='user-role-trigger'>
                Role
              </FormLabel>
              <FormSelect
                name='role'
                required
                disabled={isPending || !originalUser || isEditingSelf}
                defaultValue={user.role}
                triggerProps={{
                  'aria-labelledby': 'user-role-label',
                  'aria-controls': '',
                  'aria-autocomplete': 'list',
                  id: 'user-role-trigger'
                }}
                items={USER_ROLE_OPTIONS}
              />
              <FormMessage match='valueMissing'>This field is required</FormMessage>
            </FormPrimitive.Field>

            <FormPrimitive.Field name='status' data-testid='user-status-field'>
              <FormLabel id='user-status-label' defaultLabel>
                Status
              </FormLabel>
              <FormSelect
                name='status'
                required
                disabled={isPending || !originalUser || isEditingSelf}
                defaultValue={user.status}
                triggerProps={{
                  'aria-labelledby': 'user-status-label',
                  'aria-controls': '',
                  'aria-autocomplete': 'list',
                  id: 'user-status-trigger'
                }}
                items={USER_STATUS_OPTIONS}
              />
              <FormMessage match='valueMissing'>This field is required</FormMessage>
            </FormPrimitive.Field>
          </>
        )}
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};
